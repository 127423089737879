export default [
    // *===============================================---*
    // *--------- UCS -------------------------------*
    // * 장애 현황 파악 *
    // * 이벤트 로그 현황 *
    // * 전체 서버 목록 *
    // *===============================================---*
    {
      path: '/ucs/servobnt',
      name: 'ucs-servobnt',
      component: () => import('@/views/ucs/ServObNt.vue'),
    },
    {
      path: '/ucs/servlog',
      name: 'ucs-servlog',
      component: () => import('@/views/ucs/ServLog.vue'),
    },
    {
      path: '/ucs/servall',
      name: 'ucs-servall',
      component: () => import('@/views/ucs/ServAll.vue'),
    },
    {
      path: '/ucs/servucsall',
      name: 'ucs-servucsall',
      component: () => import('@/views/ucs/ServUcsAll.vue'),
    },
    // *===============================================---*
    // *--------- CMS -------------------------------*
    // * Monthly 미팅 통계 *
    // * MetRoom 미팅룸 *
    // * Calls 진행중인 미팅 *
    // * Resv 예약 미팅 *
    // * SysState 시스템 상태 *
    // * RltmNetwork 참석자별 실시간 네트워크 현황 *
    // *===============================================---*
    {
      path: '/cms/monthly',
      name: 'cms-monthly',
      component: () => import('@/views/cms/Monthly.vue'),
    },
    {
      path: '/cms/metroom',
      name: 'cms-metroom',
      component: () => import('@/views/cms/MetRoom.vue'),
    },
    {
      path: '/cms/calls',
      name: 'cms-calls',
      component: () => import('@/views/cms/Calls.vue'),
    },
    {
      path: '/cms/cmslogday',
      name: 'cms-log-day',
      component: () => import('@/views/cms/CmsLogDay.vue'),
    },
    {
      path: '/cms/cmslogmonth',
      name: 'cms-log-month',
      component: () => import('@/views/cms/CmsLogMonth.vue'),
    },
    {
      path: '/cms/resv',
      name: 'cms-resv',
      component: () => import('@/views/cms/Resv.vue'),
    },
    {
      path: '/cms/sysstate',
      name: 'cms-sysstate',
      component: () => import('@/views/cms/SysState.vue'),
    },
    {
      path: '/cms/rltmnetwork',
      name: 'cms-rltmnetwork',
      component: () => import('@/views/cms/RltmNetwork.vue'),
    },
    // *===============================================---*
    // *--------- VCM 코덱 -------------------------------*
    // * fault Fault Codec 상태 *
    // * acti Activation Codec 상태 *
    // * usege Usege Codec 상태 *
    // * CodecSttus Codec 상태 *
    // *===============================================---*
    {
      path: '/vcm/fault',
      name: 'vcm-fault',
      component: () => import('@/views/vcm/Fault.vue'),
    },
    {
      path: '/vcm/acti',
      name: 'vcm-acti',
      component: () => import('@/views/vcm/Acti.vue'),
    },
    {
      path: '/vcm/usege',
      name: 'vcm-usege',
      component: () => import('@/views/vcm/Usege.vue'),
    },
    {
      path: '/vcm/codecsttus',
      name: 'vcm-codecsttus',
      component: () => import('@/views/vcm/CodecSttus.vue'),
    },
    {
      path: '/vcm/codecmng',
      name: 'vcm-codecmng',
      component: () => import('@/views/vcm/CodecMng.vue'),
    },
  ]
  
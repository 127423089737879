const currentUrl = window.location
let currwss = 'wss:'
let currhttps = 'https:'
// 서버 반영 시 http로 변경 5번 8번줄 wss => ws , https: => http : 2021-05-18
if (currentUrl.protocol == 'http:') {
  // currwss = 'wss:'
  currwss = 'ws:'
  // currhttps = 'https:'
  currhttps = 'http:'
} else {
  currwss = 'wss:'
  // currwss = 'ws:'
  currhttps = 'https:'
  // currhttps = 'http:'
}
// console.log(currentUrl.protocol)
import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
let url = require('/public/d.json');
// Node BackEnd
// const nBack = `${currhttps}//meet.saesolsoft.com/`
const nBack = `${currhttps}//${url[0].webhook}` // VM 1번
// const nBack = `http://localhost:1331`
// const nBack = `${currhttps}//10.24.10.219/` // VM 2번
// JAVA BackEnd
// const jBack = `${currhttps}//dev.saesolsoft.com/ecs/`
const jBack = `${currhttps}//${url[0].dev}/ecs/` // VM 1번

// const jBack = `http://localhost:8081/ecs/`
// const jBack = `${currhttps}//10.24.10.217/ecs/` // VM 2번
// UCS Local Node BackEnd
const lUcsBack = `http://localhost:1330/`
// Codec Local Node BackEnd
const lCodecBack = `http://localhost:1339/`
// Java Local BackEnd
const lJBack = `http://localhost:8081/ecs/`

// Modules
// import createPersistedState from 'vuex-persistedstate'
// import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import createPersistedState from 'vuex-persistedstate'

import store from '@/store/index'

Vue.use(Vuex)
// Vue.prototype.wsurl = `${currwss}//10.24.10.219/api` // 웹소켓 URL // 개발
// Vue.prototype.socket = new WebSocket(`${currwss}//10.24.10.219/api`) // 개발

// Vue.prototype.wsurl = `${currwss}//meet.saesolsoft.com/api` // 웹소켓 URL // 개발 캐스트 새솔
Vue.prototype.wsurl = `${currwss}//${url[0].webhook}/api` // 웹소켓 URL // 로컬 캐스트 새솔
// Vue.prototype.wsurl = `${currwss}//10.24.10.218/api` // VM 1번
// Vue.prototype.wsurl = `${currwss}//10.24.10.219/api` // VM 2번

// Vue.prototype.ucssocket = new WebSocket(`${currwss}//meet.saesolsoft.com/ucsapi`) // 로컬 및 개발 캐스트 새솔
Vue.prototype.ucssocket = new WebSocket(`${currwss}//${url[0].webhook}/ucsapi`) // VM 1번
// Vue.prototype.ucssocket = new WebSocket(`${currwss}//10.24.10.219/ucsapi`) // VM 2번
// Vue.prototype.ucssocket = new WebSocket(`ws://localhost:1330/ucsapi`) // 로컬 및 개발 캐스트 새솔

// Vue.prototype.cmssocket = new WebSocket(`${currwss}//meet.saesolsoft.com/cmsapi`) // 로컬 및 개발 캐스트 새솔
Vue.prototype.cmssocket = new WebSocket(`${currwss}//${url[0].webhook}/cmsapi`) // VM 1번
// Vue.prototype.cmssocket = new WebSocket(`${currwss}//10.24.10.219/cmsapi`) // VM 2번
Vue.prototype.currwss = currwss

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    // 'app-ecommerce': ecommerceStoreModule,
  },
  state: {
    socket: {
      isConnected: false,
      message: '',
      reconnectError: false,
    },
    sereies: [],
    ucsServerList: [],
    cmsServerList: [],
    ucsList: {
      allFaultList: [],
      allEventList: [],
      ucsTotalItem: [],
      infoItem: [],
      criticalItem: [],
      majorItem: [],
      minorItem: [],
      warningItem: [],
      clearedItem: [],
    },
    cmsList: {
      reserveList: {},
      systemList: {},
      recentList: {},
      callsList: {},
      meetingList: {},
    },
    reserveList: [],
    systemList: [],
    recentList: [],
    callsList: [],
    meetingList: [],
    cookie: '',
    changcomp: 0,
    servList: [],
    servAllList: [],
    servStus: false,
    mainCodecList: [],
  },
  plugins: [ //refresh keep store
    createPersistedState()
  ],
  mutations: {
    /**
     * UCS 서버 실시간 리스트
     * @author 박재헌
     * @param {Object} state 
     * @param {Array} ucsServerList 
     * @description UCS 서버 실시간 리스트 state에 저장
     */
    updateUcsServerList(state, ucsServerList) {
      state.ucsServerList = ucsServerList
    },
    /**
     * CMS 서버 실시간 리스트
     * @author 박재헌
     * @param {Object} state 
     * @param {Array} cmsServerList 
     * @description CMS 서버 실시간 리스트 state에 저장 
     */
    updateCmsServerList(state, cmsServerList) {
      state.cmsServerList = cmsServerList
    },
    /**
     * CMS 미팅 예약 리스트
     * @author 박재헌
     * @param {Object} state 
     * @param {Array} reserveList 
     * @description 실시간 미팅 예약 리스트 state에 실시간 저장
     */
    updateReserveList(state, reserveList) {
      state.cmsList.reserveList = reserveList
      // console.log(reserveList)
    },
    /**
     * CMS 시스템 상태 리스트
     * @author 박재헌
     * @param {Object} state 
     * @param {Array} systemList 
     * @description 실시간 시스템 상태 차트리스트 state에 저장 및 데이터 업데이트
     */
    updateSystemList(state, systemList) {
      state.cmsList.systemList = systemList

      // CMS 차트 데이터 업데이트 
      let cmsServList = state.cmsServerList
      let actData = []
      let actItem = 0
      let videoOutData = []
      let videoOutItem = 0
      let videoInData = []
      let videoInItem = 0
      let audioOutData = []
      let audioOutItem = 0
      let audioInData = []
      let audioInItem = 0
      for (let index = 0; index < cmsServList.length; index++) {
        const element = cmsServList[index];
        if (state.cmsList.systemList[`${element.sn}`]) {
          if (state.sereies[index].sn == systemList[`${element.sn}`].sn) {

            // CMS 참석 단말수
            actData = state.sereies[index].callActSeries[0].data
            actItem = systemList[`${element.sn}`].payload.callLegsActive
            state.sereies[index].callActSeries[0].data = [actData[1], actData[2], actData[3], actItem]

            // CMS VIDEO OUT
            videoOutData = state.sereies[index].videoOutSeries[0].data
            videoOutItem = systemList[`${element.sn}`].payload.videoBitRateOutgoing
            state.sereies[index].videoOutSeries[0].data = [videoOutData[1], videoOutData[2], videoOutData[3], videoOutItem]

            // CMS VIDEO IN
            videoInData = state.sereies[index].videoInSeries[0].data
            videoInItem = systemList[`${element.sn}`].payload.videoBitRateIncoming
            state.sereies[index].videoInSeries[0].data = [videoInData[1], videoInData[2], videoInData[3], videoInItem]

            // CMS AUDIO OUT
            audioOutData = state.sereies[index].audioOutSeries[0].data
            audioOutItem = systemList[`${element.sn}`].payload.audioBitRateOutgoing
            state.sereies[index].audioOutSeries[0].data = [audioOutData[1], audioOutData[2], audioOutData[3], audioOutItem]

            // CMS AUDIO IN
            audioInData = state.sereies[index].audioInSeries[0].data
            audioInItem = systemList[`${element.sn}`].payload.audioBitRateIncoming
            state.sereies[index].audioInSeries[0].data = [audioInData[1], audioInData[2], audioInData[3], audioInItem]
          }
        }
      }
    },
    /**
     * CMS 최근 종료된 미팅 리스트
     * @author 박재헌
     * @param {Object} state 
     * @param {Array} recentList 
     * @description 실시간 최근 종료된 미팅 리스트 state에 저장
     */
    updateRecentList(state, recentList) {
      state.cmsList.recentList = recentList
    },
    /**
     * CMS 진행중인 미팅 리스트
     * @author 박재헌
     * @param {Object} state 
     * @param {Array} callsList 
     * @description 실시간 진행중인 미팅 리스트 state에 저장
     */
    updateCallsList(state, callsList) {
      state.cmsList.callsList = callsList
      // console.log(state.cmsList)

      // CMS 차트 데이터 업데이트
      let cmsServList = state.cmsServerList
      let progrsData = []
      let progrsItem = 0

      for (let index = 0; index < cmsServList.length; index++) {
        const element = cmsServList[index];

        if (state.cmsList.callsList[`${element.sn}`]) {
          if (state.sereies[index].sn == state.cmsList.callsList[`${element.sn}`].sn) {

            // CMS 진행중인 미팅
            progrsData = state.sereies[index].progrsMetSeries[0].data
            progrsItem = state.cmsList.callsList[`${element.sn}`].payload.totCalls
            state.sereies[index].progrsMetSeries[0].data = [progrsData[1], progrsData[2], progrsData[3], progrsItem]
          }
        }
      }
    },
    /**
     * CMS 미팅룸 리스트
     * @author 박재헌
     * @param {Object} state 
     * @param {Array} meetingList 
     * @description 실시간 미팅룸 리스트 state에 저장
     */
    updateMeetingList(state, meetingList) {
      // console.log(meetingList)
      state.cmsList.meetingList = meetingList
    },
    // UCS WS
    updateUcsFaultList(state, allFaultList) { // ucs ws 전체 서버 장애 현황 조회
      state.ucsList.allFaultList.push(allFaultList)
      // console.log('UCS ITEM TEST', state.ucsList)

      // state.ucsList.infoItem = state.ucsList.allFaultList.filter((x) => {
      //   return x.severity === "info"
      // })
      // state.ucsList.criticalItem = state.ucsList.allFaultList.filter((x) => {
      //   return x.severity === "critical"
      // })
      // state.ucsList.majorItem = state.ucsList.allFaultList.filter((x) => {
      //   return x.severity === "major"
      // })
      // state.ucsList.minorItem = state.ucsList.allFaultList.filter((x) => {
      //   return x.severity === "minor"
      // })
      // state.ucsList.warningItem = state.ucsList.allFaultList.filter((x) => {
      //   return x.severity === "warning"
      // })
      // state.ucsList.clearedItem = state.ucsList.allFaultList.filter((x) => {
      //   return x.severity === "cleared"
      // })

      // console.log('UCS ITEM TEST 2', state.ucsList)
      // state.ucsList.allFaultList = allFaultList
    },
    updateUcsEvtList(state, allEventList) { // ucs ws 전체 이벤트 로깅
      if (state.ucsList.allEventList.length > 0) {
        if (state.ucsList.allEventList[0].sn === allEventList.sn && state.ucsList.allEventList[0].occur == allEventList.occur && state.ucsList.allEventList[0].ip && allEventList.ip) {
          // state.ucsList.allEventList = []
          // console.log('1111')
        }
      } else {
        // console.log('2222', state.ucsList.allEventList[0])
        // console.log('3333', allEventList)
        state.ucsList.allEventList.unshift(allEventList)
      }
      // console.log('******* store commit',state.ucsList.allEventList)
      // console.log('UCS ITEM TEST', state.ucsList.allEventList)
      // state.ucsList.allEventList = allEventList
    },
    chngUcsFaultList(state, allFaultList) { // ucs ws 전체 서버 장애 현황 조회
      state.ucsList.allFaultList = allFaultList
      // state.ucsList.allFaultList.unshift(allFaultList)
    },
    chngUcsEvtList(state, allEventList) { // ucs ws 전체 이벤트 로깅
      state.ucsList.allEventList = allEventList
      // state.ucsList.allEventList.unshift(allEventList)
    },
    updateCmsChartData(state, chartData) {
      state.sereies = chartData
    },
    updateCookie(state, cookie) {
      state.cookie = cookie
    },
    updateAllServList(state, servList) { // ucs 권역 전체 리스트 및 fault cnt
      state.servList = servList
    },
    updateUcsAllServList(state, servAllList) { // ucs 권역별 전체 서버 리스트
      state.servAllList = servAllList
    },
    updateUcsAllServListStus(state, servStus) { // ucs 권역별 전체 서버 리스트 reponse status
      state.servStus = servStus
    },
    updateMainCodecList(state, mainCodecList) { // 메인 코덱 차트 리스트 저장 
      state.mainCodecList = mainCodecList
    },
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget
      state.socket.isConnected = true
    },
    SOCKET_ONCLOSE(state, event) {
      state.socket.isConnected = false
    },
    SOCKET_ONERROR(state, event) {
      console.error(state, event)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      // console.log('TTTTTTTTTTTTT 1',state)
      // console.log('TTTTTTTTTTTTT 2',message)
      // state.socket.message = message
      // localStorage.setItem("msg", JSON.stringify(message))
      // console.log(message.data);
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      // console.info(state, count)
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },
    CHANG_COMP(state, message) {
      state.changcomp = message
    },
  },
  actions: {
    sendMessage: function (context, message) {
      Vue.prototype.$socket.send(message)
    },
    async LOGIN({ commit }, logindata) {
      return await axios
        // .post(`${currhttps}//10.24.10.219/ecs/uat/uia/actionLoginSystem`, logindata) // 삼성 운영
        .post(`${jBack}uat/uia/actionLoginSystem`, logindata) // 개발 캐스트 새솔
        // .post(`${currhttps}//localhost:8081/ecs/uat/uia/actionLoginSystem`, logindata) // 로컬
        .then((response) => {
          return response;
        })
    },
    async getUcsServer() {
      return await axios
        // .post(`${currhttps}//10.24.10.219/ucsapi/servers`) // 삼성 운영
        .post(`${nBack}/ucsapi/servers`) // 개발 캐스트 새솔
        // .post(`http://localhost:1330/ucsapi/servers`) // 로컬 캐스트 새솔
        .then((response) => {
          if (response.data.resultCode == "success") {
            store.commit('updateUcsServerList', response.data.data)
          } else {
            store.commit('updateUcsServerList', [])
          }
        })
    },
    async getCmsServer() {
      return await axios
        // .post(`${currhttps}//10.24.10.219/cmsapi/servers`) // 삼성 운영
        .post(`${nBack}/cmsapi/servers`) // 개발 캐스트 새솔
        .then((response) => {
          if (response.data.resultCode == "success") {
            store.commit('updateCmsServerList', response.data.data)

            // CMS 차트 변수 서버 수만큼 저장
            let cmsServerList = store.state.cmsServerList
            store.state.sereies = []
            for (let index = 0; index < cmsServerList.length; index++) {
              const element = cmsServerList[index];
              store.dispatch(`setChartData`, element.sn)
            }

            store.commit('updateCmsChartData', store.state.sereies)
          } else {
            store.commit('updateCmsServerList', [])
          }
        })
    },
    async setChartData(areas, i) {
      let chartData = []
      // console.log(areas, i)
      chartData[`${i}`] = {
        sn: i,
        progrsMetSeries: [{
          name: "CMS 진행중인 미팅",
          data: [0, 0, 0, 0]
        }],
        callActSeries: [{
          name: "CMS 참석 단말 수",
          data: [0, 0, 0, 0]
        }],
        videoOutSeries: [{
          name: "CMS VIDEO OUTGOING",
          data: [0, 0, 0, 0]
        }],
        videoInSeries: [{
          name: "CMS VIDEO INCOMMING",
          data: [0, 0, 0, 0]
        }],
        audioOutSeries: [{
          name: "CMS AUDIO OUTGOING",
          data: [0, 0, 0, 0]
        }],
        audioInSeries: [{
          name: "CMS AUDIO INCOMMING",
          data: [0, 0, 0, 0]
        }],
      }
      store.state.sereies.push(chartData[`${i}`])
      return store.state.sereies
    },
  },
  strict: process.env.DEV,
})

const currentUrl = window.location
let currwss = 'wss:'
let currhttps = 'https:'
// 서버 반영 시 http로 변경 5번 8번줄 wss => ws , https: => http : 2021-05-18
if (currentUrl.protocol == 'http:') {
  // currwss = 'wss:'
  currwss = 'ws:'
  // currhttps = 'https:'
  currhttps = 'http:'
} else {
  currwss = 'wss:'
  // currwss = 'ws:'
  currhttps = 'https:'
  // currhttps = 'http:'
}

import Vue from 'vue'

// axios
import axios from 'axios'

let url = require('/public/d.json');
/*************** UCS LOCAL AXIOS CREATE ********************* */
const localUrl = `http://localhost:8081/ecs` // 개발서버 전용


const axiosIns = axios.create({
  baseURL: localUrl
})
/*************** Node BackEnd AXIOS CREATE UCS ********************* */

// const nodeBaseUrl = `${currhttps}//meet.saesolsoft.com/` // 개발서버 전용
const nodeBaseUrl = `${currhttps}//${url[0].webhook}/` // VM 1번 서버
// const nodeBaseUrl = `http://localhost:1339/`
// const nodeBaseUrl = `${currhttps}//10.24.10.219/` // VM 2번 서버

const nodeUrl = axios.create({
  baseURL: nodeBaseUrl,
})


/*************** JAVA BackEnd AXIOS CREATE ********************* */

// const javaBaseUrl = `${currhttps}//dev.saesolsoft.com/ecs/` // 개발서버 전용
const javaBaseUrl = `${currhttps}//${url[0].dev}/ecs/` // VM 1번 서버
// const javaBaseUrl = `${currhttps}//10.24.10.217/ecs/` // VM 2번 서버

const axiosJavaUrl = axios.create({
  baseURL: javaBaseUrl,
})

/*************** Localhost UCS Node BackEnd AXIOS CREATE ********************* */

const localUcsNodeUrl = 'http://localhost:1330/' // 로컬서버용 (pjh)

const axiosLocalUcsNodeUrl = axios.create({
  baseURL: localUcsNodeUrl,
})

/*************** Localhost Codec Node BackEnd AXIOS CREATE ********************* */

const localCodecNodeUrl = 'http://localhost:1339/' // 로컬서버용 (pjh)

const axiosLocalCodecNodeUrl = axios.create({
  baseURL: localCodecNodeUrl,
})

/*************** Localhost Java BackEnd AXIOS CREATE ********************* */

const localJavaUrl = 'http://localhost:8081/ecs/' // 로컬서버용 (pjh)

const axiosLocalJavaUrl = axios.create({
  baseURL: localJavaUrl,
})



/** PRORTOTYPE URL THIS */
// Local
Vue.prototype.$local = axiosIns
// Node BackEnd
Vue.prototype.$nBack = nodeUrl;
// JAVA BackEnd
Vue.prototype.$jBack = axiosJavaUrl
// UCS Node Local BackEnd
Vue.prototype.$lUcsBack = axiosLocalUcsNodeUrl
// Codec Node Local BackEnd
Vue.prototype.$lCodecBack = axiosLocalCodecNodeUrl
// Java Local BackEnd
Vue.prototype.$lJBack = axiosLocalJavaUrl


export default axiosIns

import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
// import apps from './routes/apps'
import dashboard from './routes/dashboard'
import frm from './routes/frm'
// import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
// import chartsMaps from './routes/charts-maps'
// import formsTable from './routes/forms-tables'
// import others from './routes/others'
import ecs from './routes/ecs'
import store from '@/store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-main' } },
    // ...apps,
    ...frm,
    ...dashboard,
    ...pages,
    // ...chartsMaps,
    // ...formsTable,
    // ...uiElements,
    // ...others,
    ...ecs,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()

//   if (!canNavigate(to)) {
//     // Redirect to login if not logged in
//     if (!isLoggedIn) return next({ name: 'auth-login' })

//     // If logged in => not authorized
//     return next({ name: 'misc-not-authorized' })
//   }

//   // Redirect if logged in
//   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//     const userData = getUserData()
//     next(getHomeRouteForLoggedInUser(userData ? userData.userSe : null))
//   }

//   return next()
// })

router.beforeEach((to, from, next) => {
  const loginVO = JSON.parse(sessionStorage.getItem('loginVO')) // 세션스토리지에서 로그인 정보 get
  // console.log(to, from, next)
  if (to.fullPath === '/' || to.fullPath === '/login') {// 로그인 페이지 진입
    if (loginVO) {// 회원 정보가 있을 때 
      if (from.name === undefined || from.name === null) {  // 라우터에 없는 페이지에서 진입
        let userSe = loginVO.userSe
        if (userSe === 'GNR') {
          next('/dashboard/main')
        }
        // if(userSe === 'ENT'){
        //   next('/')
        // }
        if (userSe === 'USR') {
          next('/dashboard/main')
        }
      } else {
        next(from.fullPath) // 라우터에 있는 페이지에서 진입 -> 전 페이지로
      }
    } else { // 회원 정보가 없을 때
      store.state.ucsServerList = []
      store.state.cmsServerList = []
      store.state.cmsList.reserveList = {}
      store.state.cmsList.systemList = {}
      store.state.cmsList.recentList = {}
      store.state.cmsList.callsList = {}
      store.state.cmsList.meetingList = {}
      store.state.ucsList.allFaultList = []
      store.state.ucsList.allEventList = []
      store.state.mainCodecList = []
      next()
    }
  } else {// 그 외 진입
    if (loginVO) {
      // console.log('그외진입')
      let userSe = loginVO.userSe
      // 로그인 사용자가 자신의 메뉴만 접근가능하게 (현재 사용 안함)
      // var regs = new RegExp("(?<=/).+(?=\/)")
      // var pathnm = regs.exec(to.fullPath);
      // var pathflg = false;
      if (userSe === 'GNR') {
        // if(to.fullPath.indexOf('ecs') > -1)
        //   next()
        // else
        //   next('/ecs/dashboard/main')
        next()
      }
      if (userSe === 'USR') {
        // if(to.fullPath.indexOf('ecs') > -1)
        //   next()
        // else
        //   next('/ecs/dashboard/main')
        next()
      }
      // if(userSe === 'ENT'){
      //   if(to.fullPath.indexOf('ecs') > -1)
      //       next()
      //   else
      //     next('/ecs/dashboard/main')
      // }
      // if(userSe === 'USR'){
      //   if(to.fullPath.indexOf('ecs') > -1){

      //     console.log('userse USR')
      //       next()
      //   }
      //   else{
      //     next('/ecs/dashboard/main')
      //   }
      // }
    }
    else {
      store.state.ucsServerList = []
      store.state.cmsServerList = []
      store.state.cmsList.reserveList = {}
      store.state.cmsList.systemList = {}
      store.state.cmsList.recentList = {}
      store.state.cmsList.callsList = {}
      store.state.cmsList.meetingList = {}
      store.state.ucsList.allFaultList = []
      store.state.ucsList.allEventList = []
      store.state.mainCodecList = []
      next('/login')  // 회원 정보가 없을 때 -> 서버 리스트 비우고 로그인 페이지로
    }
  }
})

export default router

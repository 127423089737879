export default [
    {
        path: '/dashboard/main/frm',
        name: 'dashboard-main-frm',
        component: () => import('@/views/frm/Main_frame.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    }
]

<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const qs = require('qs')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  data() {
    return {
      ucsUrl: 'nuova',
      loginParam: {
        anuova: 1,
        id: 'admin',
        pw: '1234Qwer',
      },
      refCookieParam: {
        anuova: 2,
        id: 'admin',
        pw: '1234Qwer',
      },
      logoutParam: {
        anuova: 3
      },
    }
  },
  created() {
    /* *
     * vue created 될 때 localstorage에 ucscookie, ucsnowtime 있는지 확인 후 
     * localstorage에 ucscookie, ucsnowtime 있다 >> ucsnowtime이 현재시간보다 작거나 같다 >> getUCSRefCookie() 함수 실행
     * localstorage에 ucscookie, ucsnowtime 없다 >> getUCSLogin() 함수 실행
     * */
    // const localCookie = localStorage.getItem('ucscookie') // localstorage cookie
    // const localNowtime = localStorage.getItem('ucsnowtime') // localstorage nowtime
    // if ((localCookie === '' || !localCookie) && (localNowtime === '' || !localNowtime)) {
    //   this.getUCSLogin()
    // } else if (localCookie && localNowtime) {
    //     if (new Date(localNowtime) <= new Date()) {
    //       this.getUCSRefCookie()
    //     } 
    // }

  },
  mounted() {
    /* *
    * 새로고침 및 페이지 나갔을 때 UCS LOGOUT 함수 실행 (getUCSLogout())
    * */
    // window.addEventListener('unload', this.getUCSLogout())
  },
  watch: {
    /* *
     * watch로 route 이동 감지
     * route 이동 할 때 마다 localstorage에 ucscookie, ucsnowtime 있는지 확인 후 
     * localstorage에 ucscookie, ucsnowtime 있다 >> nowtime이 현재시간보다 작거나 같다 >> getUCSRefCookie() 함수 실행
     * localstorage에 ucscookie, ucsnowtime 없다 >> getUCSLogin() 함수 실행
     * */
    $route() {
      // console.log(to, from)
      // const localCookie = localStorage.getItem('ucscookie') // localstorage cookie
      // const localNowtime = localStorage.getItem('ucsnowtime') // localstorage nowtime
      // if ((localCookie || !localCookie) && (localNowtime === '' || !localNowtime)) {
      //   this.getUCSLogin()
      // } else if (localCookie && localNowtime) {
      //     if (new Date(localNowtime) <= new Date()) {
      //       this.getUCSRefCookie()
      //     }
      // }
    },
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  // beforeUnmount() {
  //   window.removeEventListener('beforeunload', this.unLoadEvent)
  // },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
  methods: {
    // unLoadEvent(e) {
    //   console.log(e)
    //   e.preventDefault()
    //   e.returnValue = ''
    // },
    // 현재시간 + 580초
    getTime() {
      // const hours = today.getHours() // 시
      // const minutes = today.getMinutes() + 9 // 분
      // const seconds = today.getSeconds() // 초
      // return `${hours}:${minutes}:${seconds}`
      const today = new Date()
      today.setSeconds(today.getSeconds() + 580)
      return today
    },
    /**
     * UCS 로그인
     * @create 2021-02-18
     * @author 정한슬
     * @returns {json}
     * @description UCS 로그인
    */
    getUCSLogin() {
      this.$jBack({
        method: 'post',
        url: this.ucsUrl,
        data: qs.stringify(this.loginParam),
      })
      .then(response => {
        localStorage.setItem('ucscookie', response.data.data.outCookie)
        localStorage.setItem('ucsnowtime', this.getTime())
        // this.$store.commit('updateCookie', response.data.data.outCookie)
      })
      .catch(() => {
        // alert('UCS API 호출에 실패하였습니다.')
        localStorage.setItem('ucscookie', '')
        localStorage.setItem('ucsnowtime', '')
        // console.log(error)
      })
    },
    /**
     * UCS 쿠키 토큰 재요청
     * @create 2021-02-19
     * @author 정한슬
     * @returns {json}
     * @description UCS 쿠키 토큰 재요청
    */
    getUCSRefCookie() {
      this.$jBack({
        method: 'post',
        url: this.ucsUrl,
        data: qs.stringify(this.refCookieParam),
      })
      .then(response => {
        localStorage.setItem('ucscookie', response.data.data.outCookie)
        localStorage.setItem('ucsnowtime', this.getTime())
        // this.$store.commit('updateCookie', response.data.data.outCookie)
      })
      .catch(() => {
        // alert('UCS API 호출에 실패하였습니다.')
        localStorage.setItem('ucscookie', '')
        localStorage.setItem('ucsnowtime', '')
        // console.log(error)
        // alert('')
      })
    },
    /**
     * UCS 로그아웃
     * @create 2021-02-19
     * @author 정한슬
     * @returns {json}
     * @description UCS 로그아웃
    */
    getUCSLogout() {
      // console.log('ucs logout')
      this.$jBack({
        method: 'post',
        url: this.ucsUrl,
        data: qs.stringify(this.logoutParam),
      })
      .then(() => {
        localStorage.setItem('ucscookie', '')
        localStorage.setItem('ucsnowtime', '')
        // console.log(response)
      })
      .catch(() => {
        localStorage.setItem('ucscookie', '')
        localStorage.setItem('ucsnowtime', '')
        // console.log(error)
      })
    },
  }
}
</script>

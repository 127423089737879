const currentUrl = window.location
let currwss = 'wss:'
let currhttps = 'https:'
// 서버 반영 시 http로 변경 5번 8번줄 wss => ws , https: => http : 2021-05-18
if (currentUrl.protocol == 'http:') {
  // currwss = 'wss:'
  currwss = 'ws:'
  // currhttps = 'https:'
  currhttps = 'http:'
} else {
  currwss = 'wss:'
  // currwss = 'ws:'
  currhttps = 'https:'
  // currhttps = 'http:'
}
// console.log(currentUrl.protocol)
import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueNativeSock from 'vue-native-websocket'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import VueIframe from 'vue-iframes'
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
// import '@/@fake-db/db'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.use(VueIframe);
Vue.component('apexchart', VueApexCharts)

// import VueSkeletonLoader from 'skeleton-loader-vue'
// Vue.use(VueSkeletonLoader)
// Vue.component('vueSkeleton', VueSkeletonLoader)

let url = require('/public/d.json');
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// Vue.use(VueNativeSock, `${currwss}//10.24.10.219/api`, { store: store, format: 'json', reconnection: true, reconnectionAttempts: 5, reconnectionDelay: 3000}) // 삼성 운영
// Vue.use(VueNativeSock, `${currwss}//meet.saesolsoft.com/api`, { store: store, format: 'json', reconnection: true, reconnectionAttempts: 5, reconnectionDelay: 3000 }) //개발 캐스트 새솔
Vue.use(VueNativeSock, `${currwss}//${url[0].webhook}/api`, { store: store, format: 'json', reconnection: true, reconnectionAttempts: 5, reconnectionDelay: 3000 }) // VM 1번 서버
// Vue.use(VueNativeSock, `${currwss}//10.24.10.219/api`, { store: store, format: 'json', reconnection: true, reconnectionAttempts: 5, reconnectionDelay: 3000}) // VM 2번 서버
// Vue.use(VueNativeSock, `ws://localhost:1339/api`, { store: store, format: 'json', reconnection: true, reconnectionAttempts: 5, reconnectionDelay: 3000 }) // 로컬 캐스트 새솔
// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

require('@/assets/scss/main.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

Date.prototype.format = function (f) {
  if (!this.valueOf()) return " ";

  var weekName = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
  var d = this;

  return f.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|a\/p)/gi, function ($1) {
    switch ($1) {
      case "yyyy": return d.getFullYear();
      case "yy": return (d.getFullYear() % 1000).zf(2);
      case "MM": return (d.getMonth() + 1).zf(2);
      case "dd": return d.getDate().zf(2);
      case "E": return weekName[d.getDay()];
      case "HH": return d.getHours().zf(2);
      case "hh": return ((h = d.getHours() % 12) ? h : 12).zf(2);
      case "mm": return d.getMinutes().zf(2);
      case "ss": return d.getSeconds().zf(2);
      case "a/p": return d.getHours() < 12 ? "오전" : "오후";
      default: return $1;
    }
  });
};

String.prototype.string = function (len) { var s = '', i = 0; while (i++ < len) { s += this; } return s; };
String.prototype.zf = function (len) { return "0".string(len - this.length) + this; };
Number.prototype.zf = function (len) { return this.toString().zf(len); };